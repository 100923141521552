<template>
<div class="productInformationDetalis">
    <div class="banner" :style="{background:'url('+require('../assets/20240205/banner1-2.jpg')+')',backgroundSize: '100% 100%', backgroundPosition: '50% 50%'}">
      <div class="content">
        <div class="cBox">
          <img class="img" :src="baseHostUrl+productsdata.imgurl2" alt="" srcset="">
          <div class="text">{{productsdata.series}}</div>
        </div>
        <div class="box">
          <div class="title" data-aos="fade-up">{{productsdata.title}}</div>
          <div class="desc_details" data-aos="fade-up">{{productsdata.desc_details}}</div>
        </div>
      </div> 
    </div> 
	<div class="model wp main1"> 
		<div class="titbox">
			<div class="tit">{{language=='cn'?'产品亮点':'Product Highlights'}}</div> 
			<div class="txt" v-if="productsdata.highlights.content">{{productsdata.highlights.content}}</div> 
		</div> 
		<div class="flexlist"> 
			<div class="list">  
				<div class="box" v-for="item,i in productsdata.highlights.list" v-if="i%2<1">
					<h3 class="tit">{{item.title}}</h3>
					<p  v-for="ite in item.item">{{ite}}</p>  
				</div>  
			</div>  
			<div class="list">  
				<div class="box" v-for="item,i in productsdata.highlights.list" v-if="i%2>=1">
					<h3 class="tit">{{item.title}}</h3>
					<p  v-for="ite in item.item">{{ite}}</p>  
				</div>  
			</div>  
		</div> 
	</div> 
	<div class="model wp main2"> 
		<div class="titbox">
			<div class="tit">{{language=='cn'?'产品解决方案':'Product Solutions'}}</div> 
		</div> 
		<img :src="baseHostUrl+productsdata.solution_img" v-if="productsdata.solution_img" class="bg pic" alt="">
		<h3  v-if="productsdata.solution_img">{{productsdata.solution.img_title}}</h3> 
		<div class="infobox"> 
			 <div  v-for="item in productsdata.solution.list"> 
				<h4 class="tit">{{item.title}}</h4> 
				<p  class="txt" v-for="ite in item.item">{{ite}}</p> 
			 </div>
		</div>  
	</div>
	<div class="model wp main3"> 
		<div class="titbox">
			<div class="tit">{{language=='cn'?'产品特性':'Product Specification'}}</div> 
		</div>  
		<div class="flexlist"  v-if="productsdata.features.showType=='list'"> 
			<div class="list">  
				<div class="box" v-for="item,i in productsdata.features.list" v-if="i%2<1">
					<h3 class="tit">{{item.title}}</h3>
					<div class="txt"> 
						<p  v-for="ite in item.item">{{ite}}</p>  
					</div>
				</div>   
			</div>
			<div class="list">
				<div class="box" v-for="item,i in productsdata.features.list" v-if="i%2>=1">
					<h3 class="tit">{{item.title}}</h3>
					<div class="txt"> 
						<p  v-for="ite in item.item">{{ite}}</p>  
					</div>
				</div>    
			</div>
		</div>  
		<table class="tablebox"  cellspacing="0" cellpadding="0" v-if="productsdata.features.showType=='table'"> 
			<tr v-if="language=='cn'">
				<th width="20%">分类</th>
				<th width="25%">特性</th>
				<th>规格</th>
			</tr>  
			<tr v-if="language=='en'" >
				<th width="20%">Category</th>
				<th width="20%">Feature</th>
				<th>Specification</th>
			</tr>   
       
			<tr v-for="item in  productsdata.features.table.line3"> 
        <td>{{item.lineText}}</td>    
        <td colspan="2">
          <div class="flexbox" v-for="ite in item.list" >  
            <p class="secondbox">{{ ite.lineText }}</p> 
            <div class="therdbox"> 
                <p v-for="it in ite.list" :class="ite.list.length>1?'lis':''">{{ it.lineText }}</p>  
            </div>
          </div>
        </td>   
			</tr>  
		</table> 
		<table class="tablelist"  cellspacing="0" cellpadding="0" v-if="productsdata.features.showType=='table'"> 
			<tr v-if="language=='cn'">
				<th>Model</th>
				<th>网口</th>
				<th>接口类型</th>
				<th>兼容速率</th>
				<th>主机接口</th>
				<th>尺寸</th>
			</tr>  
			<tr v-if="language=='en'">
				<th>Model</th>
				<th>Network Ports</th>
				<th>Network Interfaces</th>
				<th>Supported Ethernet Speeds</th>
				<th>Host Interfaces</th>
				<th>Form factor</th>
			</tr>  
			<tr v-for="item in productsdata.features.table.line6"> 
				<td  v-for="ite in item">{{ite}}</td>  
			</tr>   
		</table>  
	</div>
</div>
</template>
<script>
import {baseHostUrl,getproductsdataPost} from '@/utils/index.js'
export default {
  name: 'productInformationDetalis',
  components: {

  },
  data () {
    return {
      pcOrmb: true,
      page: 1,
      productsdata: {
        highlights:{},
        solution:{},
        features:{},
      },
      baseHostUrl: '',
      count: 0,
      noData: false, 
	  language: localStorage.getItem('language').toLowerCase(),  
    }
  }, 
  created() {
    if ((navigator.userAgent.match(/(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i))) {  
      this.pcOrmb = false
    }else{  
      this.pcOrmb = true
    } 
  },
  mounted() {
    this.getproductsdataPost()
    this.baseHostUrl = baseHostUrl() 
  },
  methods: {

    async getproductsdataPost() {
      let data = {
        type: localStorage.getItem('language').toLowerCase(),
        product_id: this.$route.query.id
      }
      
      let res = await getproductsdataPost(data)
      this.productsdata = res.data[0]
      // this.count = res.data.count
      // if (res.data.list.length < 10){
      //   this.noData = true
      // }
    },
  }
}
</script>

<style lang="scss" scoped>

.dynamic{
  background: #F6F7F9;
} 
.banner{
  // height: 600px;
  padding: 7vw 0;
  display: flex;
  align-items: center;
  background-size:  cover !important;
  background-position: 50% 50% !important;
  .content{
    width: 63vw;
    margin: auto;
    display: flex;
    align-items: center;
  flex-direction: row-reverse;
    .cBox{
      margin-left: auto;
      .img{
        width: 19vw;
        margin: auto;
      }
      .text{
        font-size: 1.2vw;
        font-weight: bold;
        color: #000;
        margin-top: 1vw;
        text-align: center;
      }
    }
    .box{
      width:30vw;
      .title{
        font-size: 1.8vw;
        font-weight: bold;
        color: #0B71B7;
      }
      .desc{
        font-size: 1.4vw;
        font-weight: bold;
        color: #0B71B7;
        margin-top: 2vw;
      }
      .desc_details{
        font-size: 0.85vw;
        line-height: 1.5vw;
        margin-top: 2vw;
        color: #666666;
      }
    }
    
  }
}
 




.model *{
	box-sizing: border-box;
	margin: 0;
	padding: 0;
}
.model.wp { 
	font-size: 0.55vw;
	padding: 6vw 18.5%;
}
.model .titbox .tit {
    padding-bottom: 1.1em;
    border-bottom: 1px solid rgba(224, 224, 224, 0.6);
    color: #0B71B7;  
    font-weight: 600;
    font-size: 3.6em;
    line-height: 1.38em;
}
.model .titbox .txt {
	font-size: 1.6em;
	line-height: 1.75em;
	color: #666666;
	margin-top: 2.68em;
}
.model .flexlist{ 
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap; 
    position: relative;
}
.model .flexlist .list{
    width: calc(50% - 2em);
}
.model .flexlist .box{
    margin-top: 4em; 
    background: #0B71B7;
    padding: 4em; 
	box-sizing: border-box;
} 
.model .flexlist .box .tit{
    font-weight: 600;
    font-size: 2em;
    color: #FFFFFF;
    line-height: 1.4em;
    padding-bottom: 1em; 
}
.model .flexlist .box p{
  position: relative;
  font-weight: 400;
  font-size: 1.6em;
  color: #ffffff;
  line-height:1.75em;  
  margin-top: 0.62em;
}  
.model .flexlist .box p{
  padding-left: 1rem;
}
.model .flexlist .box p::before{
  content: '';
  width: 0.3rem;
  height: 0.3rem;
  border-radius: 50%;
  position: absolute;
  left: 0;
  top: calc(0.6em);
}
.main1 .flexlist .box p::before{
  background: #fff;
}
.main3 .flexlist .box p::before{
  background: #0B71B7;
}
.main2{ 
    background: #F2F4F7;
}
.main2 .pic{
    width: 70%;
    margin: 4.5em auto 4em;
}

.main2 h3{
    font-weight: bold;
font-size: 2.4em;
text-align: center;
color: #000000;
margin: 1.66em 0 3.33em;
}
.main2 .infobox .tit{
    font-weight: bold;
font-size: 2em;
color: #333;
margin: 2em 0 1.4em;
}
.main2 .infobox .txt{ 
font-size: 1.6em; 
color: #333; 
line-height: 1.75em;
margin-top: 0.62em;
}
.main3 .flexlist .box{
    background: #F2F4F7;
    border: 1px solid #E0E4E9;
    padding: 0; 
} 
.main3 .flexlist .box .tit{ 
    line-height: 3em;
    padding-bottom: 0; 
    background: #0B71B7;
    padding: 0 1.5em;
}
.main3 .flexlist .box .txt{
    padding: 3em;
} 
.main3 .flexlist .box p{ 
    color: #333; 
}

.main3 .tablebox{ 
    width: 100%;
    text-align: left;
    border: 1px solid #EEEEEE;
    line-height: 2.4em;
    font-size: 1.6em;
    color: #333333;
    border-collapse: collapse;
} 

.main3 .tablelist{
    margin-top: 6.25em;
    border: 0;
    width: 100%;
    border: 1px solid #EEEEEE;
    text-align: left;
    line-height: 2.4em;
    font-size: 1.6em;
    color: #333333;
    border-collapse: collapse;
} 
 
.tablebox th,
.tablebox td{
  border: 1px solid #EEEEEE;
  padding: 0.62em 3.12em;
}   
.tablelist th,
.tablelist td { 
    padding: 0.62em .87em;
}
th{
    background: #0B71B7;
    border: 1px solid #0B71B7;
    color: #fff; 
}
 th:first-child,
 td:first-child { 
    padding: 1em 2em;
}
.tablebox th,
.tablebox td:first-child {  
    font-weight: bold;
}

tr:nth-child(2n+1){
    background: #F2F4F7;
}
@media screen and (max-width:750px){
  
.banner{ 
    background-size:  cover !important;
    background-position: 50% 50% !important;
	.content{
		display: block;
		margin: 0 15px;
		width: 100%;
		.cBox{ 
		  .img{
		    width: 70%;
		    margin: auto;
		  }
		  .text{
		    font-size:4vw;  
		  }
		}
		.box{
		  width: 100%;
		  .title{
			font-size: 6vw;
			font-weight: bold;
			color: #0B71B7;
			text-align: center;
			margin-top: 6vw;
		  }
		  .desc_details{
			font-size: 4vw;
			line-height: 8vw;
			margin-top: 10px;
			color: #666666;
			text-align: justify;
		  }
		}
	  }
  }
  
 .model.wp { 
 	font-size: 2vw;
 	padding: 10vw 5%;
 }
 
 .model .titbox .tit { 
     font-size: 2.8em; 
 }
 .model .titbox .txt {
 	font-size: 1.6em;
 	line-height: 1.75em;
 	color: #666666;
 	margin-top: 2.68em;
 }
 .model .flexlist{ 
     display: block; 
     position: relative;
 }
 .model .flexlist .list { 
     width: calc(100%); 
 }
}
</style>

<style lang="scss" scoped>
.tablebox{
  td:last-child{
    padding: 0;  
    .flexbox{
      display: flex;
      justify-content: space-between;
      align-items: stretch‌; 
      &+.flexbox{ 
        border-top: 1px solid #EEEEEE; 
      }
      .secondbox{
        width: 31.3%; 
        border-right: 1px solid #EEEEEE; 
      }
      .therdbox{
        width: 68.7%;  
      }

      p{
        padding: 0.62em 3.12em; 
        display: flex;

        justify-content: center;
        align-items: flex-start;
        flex-direction: column;
        &+p{ 
          border-top: 1px solid #EEEEEE; 
        }
      }
    } 
  }
}
</style>